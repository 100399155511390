<template>
    <v-form>
        <v-container>
            <div v-if="!isSending">
                <v-row class="mt-4" justify="center">
                    <v-col cols="12" md="6">
                        <v-btn block @click="goBack" color="secondary">
                            Terug
                        </v-btn> </v-col
                    ><v-col cols="12" md="6">
                        <v-btn block @click="goToOld" color="primary">
                            Naar oude versie
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <div class="display-1 mt-4">
                        <p>
                            <span class="caption">Nieuw</span>
                        </p>
                        <p>
                            Gegevens rapport
                        </p>
                    </div>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            id="input-temp-name"
                            outlined
                            label="Tijdelijke naam"
                            v-model="form.type"
                            hide-details
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="10">
                        <v-combobox
                            id="input-klant"
                            outlined
                            :items="customers.map(user => user.naam)"
                            label="Klant"
                            v-model="form.klant"
                            required
                            hide-details
                        >
                        </v-combobox>
                    </v-col>
                    <v-col cols="12" md="2" class="d-flex justify-center">
                        <v-chip color="red" large v-if="klantBestaat">
                            Klant bestaat niet
                        </v-chip>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field
                            id="input-2"
                            outlined
                            label="Datum taxatie"
                            v-model="form.datum_taxatie"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="8">
                        <v-text-field
                            id="input-3"
                            outlined
                            label="Doel taxatie"
                            v-model="form.doel_taxatie"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            id="input-4"
                            outlined
                            label="Tijd aanvang"
                            v-model="form.tijd_aanvang"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            id="input-5"
                            outlined
                            label="Tijd vertrek"
                            v-model="form.tijd_vertrek"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field id="input-6" outlined label="Dossiernummer" v-model="form.dossiernummer" required>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            id="input-rapportnummer"
                            outlined
                            label="Rapportnummer / Datum"
                            v-model="form.rapportnummer"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            outlined
                            label="Reden taxatie"
                            value="Betreft een voertuig met meer dan normale gebruikersschade, niet zijnde een schadevoertuig als bedoeld in artikel 1, eerste lid, onderdeel van de wegenverkeerswet 1994."
                            disabled
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <div class="display-1">Gegevens voertuig</div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-select
                            id="input-10"
                            outlined
                            label="Soort voertuig"
                            :items="soorten"
                            v-model="form.soort_voertuig"
                            hide-details
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            id="input-kleur"
                            outlined
                            label="Kleur (wordt automatisch gevuld met N.V.T. als niet ingevuld)"
                            v-model="form.kleur"
                            hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="false">
                        <v-text-field
                            id="input-chassisnummer"
                            outlined
                            label="Chassisnummer"
                            v-model="form.chassisnummer"
                            :rules="chassisnummerRules"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="false">
                        <v-combobox
                            id="input-merk"
                            outlined
                            :items="merken"
                            label="Merk"
                            v-model="form.puppeteer.merk"
                            @change="setModellen"
                            required
                            hide-details
                        >
                        </v-combobox>
                    </v-col>
                    <v-col cols="12" v-if="false">
                        <v-combobox
                            id="input-model"
                            outlined
                            :items="modellen"
                            :disabled="form.puppeteer.merk.length === 0"
                            label="Model"
                            v-model="form.puppeteer.model"
                            required
                            hide-details
                        >
                        </v-combobox>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            id="input-jaar"
                            outlined
                            label="Bouwjaar"
                            suffix="jaar"
                            v-model="form.puppeteer.bouwjaar"
                            hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-combobox
                            id="input-brandstof"
                            outlined
                            :items="brandstoffen"
                            label="Brandstof"
                            v-model="form.puppeteer.brandstof"
                            required
                            hide-details
                        >
                        </v-combobox>
                    </v-col>
                    <v-col cols="12">
                        <v-combobox
                            id="input-transmissie"
                            outlined
                            :items="transmissies"
                            label="Transmissie"
                            v-model="form.puppeteer.transmissie"
                            required
                            hide-details
                        >
                        </v-combobox>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            id="input-vermogen"
                            outlined
                            label="Vermogen"
                            suffix="KW"
                            v-model="form.puppeteer.vermogen"
                            hide-details
                            v-if="false"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            id="input-kmstand"
                            outlined
                            label="KM-stand"
                            suffix="KM"
                            v-model="form.puppeteer.kmstand"
                            hide-details
                        ></v-text-field>
                    </v-col>

                    <v-col cols="4" class="title text-center">
                        <v-btn block color="primary">
                            Ga naar gaspedaal
                        </v-btn>
                    </v-col>
                    <v-col cols="4" class="title text-center">
                        <v-btn block color="secondary">
                            Ga naar autoscout24
                        </v-btn>
                    </v-col>
                    <v-col cols="4" class="title text-center">
                        <v-btn block color="primary">
                            Ga naar autowereld
                        </v-btn>
                    </v-col>
                    <v-col cols="12">
                        <span class="title">Is de auto al rdw gekeurd?</span>
                        <v-radio-group id="radio-rdw-gekeurd" v-model="rdw_gekeurd" row>
                            <v-radio value="ja" label="Ja"> </v-radio>
                            <v-radio value="nee" label="Nee"></v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col cols="12" v-if="rdw_gekeurd === 'ja'">
                        <v-text-field
                            id="input-rdw-gekeurd"
                            outlined
                            label="Datum RDW gekeurd"
                            v-model="form.datum_rdw_keuring"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <div class="display-1">Staat voertuig</div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-select
                            id="input-23"
                            outlined
                            label="Staat wielophanging"
                            :items="staat_voertuig.wielophanging"
                            v-model="form.staat.wielophanging"
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-select
                            id="input-24"
                            outlined
                            label="Velgen/banden"
                            :items="staat_voertuig.velgen_banden"
                            v-model="form.staat.velgen_banden"
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-select
                            id="input-25"
                            outlined
                            label="Stuurinrichting"
                            :items="staat_voertuig.stuurinrichting"
                            v-model="form.staat.stuurinrichting"
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-select outlined label="Remmen" :items="staat_voertuig.remmen" v-model="form.staat.remmen">
                        </v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-select
                            id="input-26"
                            outlined
                            label="Versnellingsbak aandrijving"
                            :items="staat_voertuig.versnellingsbak"
                            v-model="form.staat.versnellingsbak"
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-select
                            id="input-27"
                            outlined
                            label="Elektrische installatie"
                            :items="staat_voertuig.elektrische_installatie"
                            v-model="form.staat.elektrische_installatie"
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-select
                            id="input-28"
                            outlined
                            label="Carroserie staat"
                            :items="staat_voertuig.carrosserie"
                            v-model="form.staat.carrosserie"
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-select
                            id="input-29"
                            outlined
                            label="Interieur"
                            :items="staat_voertuig.interieur"
                            v-model="form.staat.interieur"
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-select
                            id="input-30"
                            outlined
                            label="Motor"
                            :items="staat_voertuig.motor"
                            v-model="form.staat.motor"
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-select
                            id="input-31"
                            outlined
                            label="Algehele staat"
                            :items="staat_voertuig.algehele_staat"
                            v-model="form.staat.algehele_staat"
                        >
                        </v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <p class="display-1">
                            Afbeeldingen
                        </p>
                        <v-btn color="secondary" @click="onButtonClick('fotoUploader')" block>
                            <v-icon> mdi-cloud-upload-outline </v-icon>&nbsp; Upload foto's
                        </v-btn>
                        <input
                            id="fileinput-foto"
                            ref="fotoUploader"
                            type="file"
                            accept="image/*"
                            multiple
                            class="d-none"
                            @change="onPhotosUpload($event)"
                        />
                        <v-btn class="mt-2 justify" color="secondary" small @click="alleFotosToggle = !alleFotosToggle">
                            {{ alleFotosToggle ? "Laat alleen 6 foto's zien" : "Laat alle foto's zien" }}
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="8">
                        <transition-group tag="div" name="slide-fade">
                            <v-img
                                class="ma-2 hover-delete foto-preview"
                                v-for="(foto, index) in fotosPreview"
                                :key="foto.url"
                                :src="getSrc(foto.url)"
                                @click="deletePhoto(index)"
                            >
                            </v-img>
                        </transition-group>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <p class="display-1">
                            Gaspedaal
                        </p>
                        <v-btn color="secondary" @click="onButtonClick('gaspedaalUploader')" block>
                            <v-icon> mdi-cloud-upload-outline </v-icon>&nbsp; Upload gaspedaal foto
                        </v-btn>
                        <input
                            id="fileinput-2"
                            ref="gaspedaalUploader"
                            type="file"
                            accept="image/*"
                            class="d-none"
                            @change="onPhotoUpload($event, 'gaspedaal')"
                        />
                    </v-col>
                    <v-col cols="12" md="8">
                        <transition name="slide-fade" mode="out-in">
                            <v-img
                                v-if="form.gaspedaal"
                                style="display: inline-block;"
                                class="ma-2"
                                :key="form.gaspedaal.url"
                                :src="getSrc(form.gaspedaal.url)"
                                height="160"
                                width="300"
                            >
                            </v-img>
                        </transition>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="9" md="4">
                        <p class="display-1">
                            Koerslijst<br />
                            <span class="caption">(wordt uit aangifteformulier gehaald als niet ingevuld)</span>
                        </p>
                        <v-btn
                            color="secondary"
                            style="height: 36px !important"
                            @click="onButtonClick('koerslijstUploader')"
                            block
                        >
                            <v-icon> mdi-cloud-upload-outline </v-icon>&nbsp; Upload koerslijst
                        </v-btn>
                        <v-btn
                            v-if="form.koerslijst"
                            class="mt-2 justify"
                            color="secondary"
                            small
                            @click="clearFormValue('koerslijst')"
                        >
                            Verwijder koerslijst
                        </v-btn>
                        <input
                            id="fileinput-koerslijst"
                            ref="koerslijstUploader"
                            type="file"
                            accept="application/pdf"
                            class="d-none"
                            @change="onDocumentUpload($event, 'koerslijst')"
                        />
                    </v-col>
                    <v-col cols="3" md="8" class="d-flex align-end">
                        <v-icon v-if="form.koerslijst" color="green" x-large>
                            mdi-check-circle
                        </v-icon>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="9" md="4">
                        <p class="display-1">
                            VWE rapport
                        </p>
                        <v-btn color="secondary" @click="onButtonClick('vweUploader')" block>
                            <v-icon> mdi-cloud-upload-outline </v-icon>&nbsp; Upload VWE rapport
                        </v-btn>
                        <input
                            id="fileinput-vwe"
                            ref="vweUploader"
                            type="file"
                            accept="application/pdf"
                            class="d-none"
                            @change="onDocumentUpload($event, 'vwe')"
                        />
                    </v-col>
                    <v-col cols="3" md="8" class="d-flex align-end">
                        <v-icon v-if="form.vwe" color="green" x-large>
                            mdi-check-circle
                        </v-icon>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="9" md="4">
                        <p class="display-1">
                            Aangifteformulier
                        </p>
                        <v-btn color="secondary" @click="onButtonClick('aangifteformulierUploader')" block>
                            <v-icon> mdi-cloud-upload-outline </v-icon>&nbsp; Upload aangifteformulier
                        </v-btn>
                        <input
                            id="fileinput-aangifteformulier"
                            ref="aangifteformulierUploader"
                            type="file"
                            accept="application/pdf"
                            class="d-none"
                            @change="onDocumentUpload($event, 'aangifteformulier')"
                        />
                    </v-col>
                    <v-col cols="3" md="8" class="d-flex align-end">
                        <v-icon v-if="form.aangifteformulier" color="green" x-large>
                            mdi-check-circle
                        </v-icon>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6">
                        <span class="title">Met of zonder art.8</span>
                        <v-radio-group id="radio-art8" v-model="form.art8" row>
                            <v-radio id="radio-art8-zonder" value="zonder" label="Zonder artikel 8"> </v-radio>
                            <v-radio
                                id="radio-art8-met"
                                value="met"
                                label="
                                Met artikel 8"
                            >
                            </v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="6">
                        <v-btn id="save-form-button" block color="primary" class="ma-2" @click="saveTaxatie(false)">
                            Opslaan
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-btn id="save-concept-button" block color="secondary" class="ma-2" @click="saveTaxatie(true)">
                            Bewaar concept
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
            <sending-component v-if="isSending" />
        </v-container>
    </v-form>
</template>

<script>
import axios from '../../axios';
import merken from './data/merken.json';
import merkenModellen from './data/merken_modellen.json';
import SendingComponent from '../Shared/SendingComponent.vue';

export default {
    data() {
        return {
            isSending: false,
            lastTaxatie: {},
            fotos: [],
            rdw_gekeurd: 'ja',
            form: {
                puppeteer: {
                    merk: '',
                    model: '',
                    uitvoering: ''
                },
                fotos: [],
                art8: 'zonder',
                doel_taxatie: 'Waardebepaling t.b.v. import/BPM inclusief BPM',
                reden_taxatie:
                    'Betreft een voertuig met meer dan normale gebruikersschade, niet zijnde een schadevoertuig als bedoeld in artikel 1, eerste lid, onderdeel van de wegenverkeerswet 1994.',
                staat: {
                    wielophanging:
                        'De wielophanging verkeert voor zover waarneembaar in een normale staat. Geen afwijkingen waargenomen.',
                    velgen_banden:
                        'De stalen velgen/banden set verkeert in een beschadigde staat. Zie bijlage SilverDat schadecalculatie.',
                    stuurinrichting:
                        'De stuurinrichting verkeert in een beschadigde staat. Zie bijlage SilverDat schadecalculatie.',
                    remmen:
                        'De reminrichting verkeert in een beschadigde staat.  Zie bijlage SilverDat schadecalculatie.',
                    versnellingsbak:
                        'De automatische versnellingsbak en de aandrijving verkeren voor zover waarneembaar in een redelijke staat. Geen afwijking/schades waargenomen.',
                    elektrische_installatie:
                        'De elektrische installatie verkeert in een beschadigde staat. Zie bijlage SilverDat schadecalculatie.',
                    carrosserie: 'De carrosserie verkeert in een goede staat.',
                    interieur:
                        'Het interieur verkeert in een beschadigde staat. Zie bijlage SilverDat schadecalculatie.',
                    motor: 'De motor verkeert zover in een beschadigde staat.  Zie bijlage SilverDat schadecalculatie.',
                    algehele_staat: 'Deze auto verkeert behoudens de schade in een normaal gebruikte staat.'
                },
                soort_voertuig: 'Personenauto',
                transmissie: 'Automaat',
                carroserie: '5-drs Hatchback',
                brandstof: 'Benzine'
            },
            customers: [],
            merken: [],
            modellen: [],
            uitvoeringen: [],
            soorten: ['Personenauto', 'Bedrijfswagen'],
            transmissies: ['Automaat', 'Handmatig'],
            carroseries: [
                '5-drs Hatchback',
                '3-drs Hatchback',
                '5-drs SUV',
                '0-drs Chassis',
                '4-drs Sedan',
                '2-drs Softtop',
                '5-drs MPV',
                '5-drs combi',
                '5-drs bus'
            ],
            brandstoffen: ['Benzine', 'Diesel', 'Aardgas', 'Hybride benzine', 'Electrisch'],
            staat_voertuig: {
                wielophanging: [
                    'De wielophanging verkeert voor zover waarneembaar in een normale staat. Geen afwijkingen waargenomen.',
                    'De wielophanging verkeert in een beschadigde staat. Zie bijlage SilverDat schadecalculatie.'
                ],
                velgen_banden: [
                    'De stalen velgen/banden set verkeert in een beschadigde staat. Zie bijlage SilverDat schadecalculatie.',
                    'De stalen velgen/banden set verkeert in een gebruikelijke staat. Geen afwijkingen/schades waargenomen.'
                ],
                stuurinrichting: [
                    'De stuurinrichting verkeert in een beschadigde staat. Zie bijlage SilverDat schadecalculatie.',
                    'De stuurinrichting verkeert in een gebruikelijke staat. Geen afwijkingen/schades waargenomen.'
                ],
                remmen: [
                    'De reminrichting verkeert in een beschadigde staat.  Zie bijlage SilverDat schadecalculatie.',
                    'De reminrichting verkeert in een gebruikelijke staat. Geen afwijkingen/schades waargenomen.'
                ],
                versnellingsbak: [
                    'De automatische versnellingsbak en de aandrijving verkeren voor zover waarneembaar in een redelijke staat. Geen afwijking/schades waargenomen.',
                    'De automatische versnellingsbak en de aandrijving verkeert in een beschadigde staat. Zie bijlage SilverDat schadecalculatie.',
                    'De handgeschakelde versnellingsbak en de aandrijving verkeren voor zover waarneembaar in een redelijke staat. Geen afwijking/schades waargenomen.',
                    'De handgeschakelde versnellingsbak en de aandrijving verkeert in een beschadigde staat. Zie bijlage SilverDat schadecalculatie.'
                ],
                elektrische_installatie: [
                    'De elektrische installatie verkeert in een beschadigde staat. Zie bijlage SilverDat schadecalculatie.',
                    'De elektrische installatie verkeert voor zover waarneembaar in een gebruikelijke staat. Geen afwijkingen/schades waargenomen.'
                ],
                carrosserie: [
                    'De carrosserie verkeert in een goede staat.',
                    'De carrosserie verkeert in een beschadigde staat.  Zie bijlage SilverDat schadecalculatie.'
                ],
                interieur: [
                    'Het interieur verkeert in een beschadigde staat. Zie bijlage SilverDat schadecalculatie.',
                    'Het interieur verkeert in een goede staat. Normale sporen van gebruik waarneembaar.'
                ],
                motor: [
                    'De motor verkeert zover in een beschadigde staat.  Zie bijlage SilverDat schadecalculatie.',
                    'De motor verkeert zover waarneembaar in een goed werkend staat. Geen afwijkingen/schades waargenomen.'
                ],
                algehele_staat: [
                    'Deze auto verkeert behoudens de schade in een normaal gebruikte staat.',
                    'Deze motor verkeert behoudens de schade in een normaal gebruikte staat.'
                ]
            },
            alleFotosToggle: false,
            editToggle: false,
            chassisnummerRules: [
                v => !!v || 'This field is required',
                v => (v && v.length >= 17) || 'This field must have 17 characters',
                v => (v && v.length <= 17) || 'This field must have 17 characters'
            ]
        };
    },
    async mounted() {
        this.merken = merken.map(merk => merk.brand);

        if (this.$route.params.id) {
            this.editToggle = true;

            const id = this.$route.params.id;
            const { data: form } = await axios.get(`/taxaties/${id}`);
            this.form = form;
            this.form.klant = this.form.klant.naam;

            console.log(this.form);

            this.form.gaspedaal =
                form.gaspedaal !== '' ? { url: `${axios.defaults.baseURL}/fotos/${form.gaspedaal}` } : form.gaspedaal;
            this.form.bpm = form.bpm !== null ? { url: `${axios.defaults.baseURL}/fotos/${form.bpm}` } : form.bpm;

            this.form.fotos =
                form.fotos.length === 0
                    ? []
                    : this.form.fotos.map(url => ({ url: `${axios.defaults.baseURL}/fotos/${url}` }));
            this.fotos = this.form.fotos;
        }

        try {
            this.customers = (await axios.get('/users')).data;

            if (!this.editToggle) {
                this.form.datum_taxatie;
                this.form.rapportnummer = this.curDate;
                this.form.datum_taxatie = this.curDate;
                this.form.datum_rdw_keuring = this.curDate;

                this.form.tijd_aanvang = '12:00';
                this.form.tijd_vertrek = '12:30';

                this.lastTaxatie = (await axios.get('/taxaties/last')).data;

                const dossiernummer = String(+this.lastTaxatie.dossiernummer + 1);
                const zeros = 6 - dossiernummer.length;
                this.form.dossiernummer = `${'0'.repeat(zeros)}${dossiernummer}`;

                this.$forceUpdate();
            }
        } catch (e) {
            console.error(e);
        }
    },
    computed: {
        klantBestaat() {
            if (!this.form.klant) {
                return false;
            }
            const index = this.customers.findIndex(customer => customer.naam === this.form.klant);
            return index === -1;
        },
        fotosPreview() {
            return this.fotos.length > 0 ? (this.alleFotosToggle ? this.fotos : this.fotos.slice(0, 6)) : [];
        },
        curDate() {
            const date = new Date();
            return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
        },
        gaspedaalUrl() {
            return 'test';
        }
    },
    methods: {
        async saveTaxatie(concept = false) {
            return concept;
            // if (this.validateForm() && !concept) {
            //     alert('Niet alle gegevens ingevuld!');
            //     return;
            // }
            // try {
            //     this.isSending = true;

            //     const postData = {
            //         ...this.form,
            //         klant: this.customers.find(customer => customer.naam === this.form.klant)._id,
            //         kleur: this.form.kleur || 'N.V.T.',
            //         rdw_gekeurd: this.rdw_gekeurd === 'ja' ? this.form.datum_rdw_keuring : this.curDate
            //     };

            //     console.log('Post data: ', postData);

            //     if (postData._id) {
            //         if (concept) {
            //             delete postData._id;
            //             await axios.put(`/taxaties/concept/${this.form._id}`, postData);
            //         } else {
            //             delete postData._id;
            //             await axios.put(`/taxaties/${this.form._id}`, postData);
            //         }
            //     } else {
            //         if (concept) {
            //             await axios.post('/taxaties/concept', postData);
            //         } else {
            //             await axios.post('/taxaties', postData);
            //         }
            //     }
            //     this.$router.replace('/taxaties/lijst');
            // } catch (error) {
            //     console.error(error);
            //     this.isSending = false;
            //     alert('Er ging iets fout, heeft u alle gegevens ingevuld?');
            // }
        },
        onButtonClick(ref) {
            this.$refs[ref].click();
        },
        async onPhotosUpload(e) {
            const nieuweFotos = await Promise.all(
                Object.values(e.target.files).map(
                    async foto =>
                        new Promise(resolve => {
                            const FR = new FileReader();

                            FR.onload = async event => {
                                resolve({
                                    foto,
                                    url: URL.createObjectURL(foto),
                                    base64: await this.compressFile(event.target.result)
                                });
                            };

                            FR.readAsDataURL(foto);
                        })
                )
            );
            this.fotos = this.fotos.concat(nieuweFotos);
            this.form.fotos = this.fotos;
            this.$forceUpdate();
        },
        async onPhotoUpload(e, type) {
            const foto = e.target.files[0];

            this.form[type] = await new Promise(resolve => {
                const FR = new FileReader();

                FR.onload = async event => {
                    resolve({
                        foto,
                        url: URL.createObjectURL(foto),
                        base64: await this.compressFile(event.target.result, 1200)
                    });
                };

                FR.readAsDataURL(foto);
            });
            this.$forceUpdate();
        },
        async onDocumentUpload(e, type) {
            const FR = new FileReader();

            FR.onload = async event => {
                this.form[type] = event.target.result;
                this.$forceUpdate();
            };

            FR.readAsDataURL(e.target.files[0]);
        },
        compressFile(src, size = 600) {
            return new Promise(resolve => {
                // Create image
                const image = document.createElement('img');

                const onLoadFn = () => {
                    // Compress image with canvas
                    const compressedSize = size;

                    const aspectRatio = image.width / image.height;

                    const cv = document.createElement('canvas');

                    cv.height = compressedSize;
                    cv.width = compressedSize * aspectRatio;

                    const ctx = cv.getContext('2d');

                    ctx.drawImage(image, 0, 0, compressedSize * aspectRatio, compressedSize);
                    const compressedUrl = cv.toDataURL('image/jpeg', 1);

                    // Clean up
                    removeEventListener('load', onLoadFn);

                    // Return value
                    resolve(compressedUrl);
                };

                // Set function to respond on load
                image.addEventListener('load', onLoadFn);

                // Set source
                image.src = src;
            });
        },
        deletePhoto(index) {
            this.fotos.splice(index, 1);
        },
        goBack() {
            this.$router.push('/taxaties/lijst');
        },
        goToOld() {
            this.$router.push('/taxaties/maak');
        },
        validateForm() {
            if (
                this.form &&
                this.form.klant &&
                this.form.datum_taxatie &&
                this.form.tijd_aanvang &&
                this.form.tijd_vertrek &&
                this.form.dossiernummer &&
                this.form.rapportnummer &&
                this.form.fotos.length > 0 &&
                this.form.gaspedaal &&
                // this.form.gaspedaal !== '' &&
                this.chassisnummer.length === 17 &&
                this.form.vwe &&
                this.form.aangifteformulier
            ) {
                return false;
            }
            return true;
        },
        clearFormValue(val) {
            this.form[val] = null;
            this.$forceUpdate();
        },
        getSrc(src) {
            return `${src}`;
        },
        setModellen() {
            this.modellen = merkenModellen
                .filter(merkObj => merkObj.brand.toLowerCase() === this.form.puppeteer.merk.toLowerCase())
                .map(merkObj => merkObj.model);
            console.log(this.modellen);
        }
    },
    components: {
        SendingComponent
    },
    metaInfo: {
        title: 'Taxatie formulier'
    }
};
</script>

<style scoped>
.hover-delete {
    opacity: 1;
}
.hover-delete:hover {
    opacity: 0.2;
    transition: 0.2s;
    filter: hue-rotate(90deg);
}
.foto-preview {
    display: inline-block;
    width: 210px;
    height: 160px;
}
</style>
